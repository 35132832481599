/***
 * @deprecated Use USER_ROLE instead
 */
export enum UserRoleEnum {
    user = 'user',
    org_admin = 'org_admin',
    super_admin = 'super_admin',
    supplier = 'supplier',
}

export enum UserFlagEnum {
    paymentAdmin = 'payment_admin',
    canViewUnallocatedClaims = 'can_view_unallocated_claims',
}

export const MODULE = {
    COMPLIANCE: 'compliance',
    CLAIMS: 'claims',
} as const;

export type Module = keyof typeof MODULE;

export const USER_ROLE = {
    USER: 'user',
    ORG_ADMIN: 'org_admin',
    SUPPLIER: 'supplier',
} as const;

export type UserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE];
