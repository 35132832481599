import React from 'react';

interface Props {
    children: React.ReactNode;
}

export function PublicLayout({ children }: Props) {
    return (
        <div className="relative flex min-h-screen w-screen flex-col justify-start bg-gray-100 md:justify-center">
            {children}
        </div>
    );
}
