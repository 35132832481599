import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { errorMessage } from '@/components/Common/errorMessage';
import { useGetAccessToken } from '@/hooks/auth/useGetAccessToken';
import { useUserEvent } from '@/hooks/Logging/useUserEvent';
import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useAuthStore } from '@/stores/AuthStore';
import { logInDev } from '@/utils/general';
import { decodeJwtToken } from '@/utils/security';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { App, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { SYSTEM_ORG_ID } from '~/global/constants/org';
import { generatePath } from '~/navigation/navigation';
import { navRoutes } from '~/navigation/navRoutes';
import * as Sentry from '@sentry/react';

export function AuthCallback() {
    const { message } = App.useApp();
    const { logout, handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();
    const authStore = useAuthStore();
    const authInProgressRef = useRef(false);
    const { insertEvent } = useUserEvent();
    const getAccessToken = useGetAccessToken();
    const queryClient = useQueryClient();
    const trpcClient = useTrpcClient();

    useEffect(() => {
        (async () => {
            Sentry.getCurrentScope().setTransactionName('Login Callback');
            queryClient.clear();

            logInDev('AuthCallback useEffect');
            if (authInProgressRef.current && !authStore.isLoginInProgress) {
                return;
            }

            try {
                authInProgressRef.current = true;
                logInDev('logging in from /callback');

                const params = new URLSearchParams(window.location.search);
                const error = params.get('error');
                if (error) {
                    if (error === 'login_required') {
                        await errorMessage.showAsync(new Error('Login required'));
                    } else if (error === 'access_denied') {
                        await errorMessage.showAsync(
                            params.get('error_description'),
                            'Access Denied',
                        );
                    }
                    authStore.logout(logout);
                    return;
                }

                const appStateWrapper = (await handleRedirectCallback()) as {
                    appState?: { redirectTo?: string };
                };

                logInDev('AuthCallback: appStateWrapper', appStateWrapper);
                const redirectTo = appStateWrapper?.appState?.redirectTo;
                const match = matchPath('/console/:orgId/*', redirectTo || '');
                const orgId = match?.params.orgId;

                logInDev('AuthCallback: orgId', orgId);

                let accessToken = await getAccessToken(orgId ? Number(orgId) : undefined, true);

                const tokenDecoded = decodeJwtToken(accessToken || '');
                const isFirstSsoLogin = tokenDecoded?.['https://curium.app/claims'].isFirstSsoLogin;

                if (isFirstSsoLogin) {
                    try {
                        await trpcClient.user.initializeSsoUser.mutate({
                            accessToken,
                        });
                        // Get new access token, it should have the updated user metadata
                        accessToken = await getAccessToken(orgId ? Number(orgId) : undefined, true);
                    } catch (e) {
                        await errorMessage.showAsync(e);
                        navigate(navRoutes.public_root.path);
                        return;
                    }
                }

                await authStore.login(accessToken);

                logInDev('AuthCallback: login complete');

                await insertEvent('login', 'User logged in successfully');

                const org = useAuthStore.getState().org;
                invariant(org, 'org is required');

                if (redirectTo && !redirectTo.includes('/login')) {
                    navigate(redirectTo);
                } else {
                    if (org.id === SYSTEM_ORG_ID.SYSTEM_CONSOLE) {
                        navigate({ pathname: navRoutes.adminPortal.root.path });
                    } else {
                        navigate({ pathname: generatePath(navRoutes.home, { orgId: org.id }) });
                    }
                }
            } catch (e) {
                if (e instanceof Error && e.message.toLowerCase().includes('invalid state')) {
                    // This is a known issue with Auth0 where the state is invalid.
                    // See: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
                    logInDev('AuthCallback: invalid state, logging out');
                    await authStore.logout(logout);
                    return;
                } else {
                    await errorMessage.showAsync(e);
                }
                message.info('Logging out...');
                authStore.logout(logout);
            } finally {
                authInProgressRef.current = false;
                message.destroy();
            }
        })();
    }, []);

    return (
        <ComponentLoading isFullPage>
            <div className="hover:shadow-3xl mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl transition-all duration-500 ease-in-out">
                <div className="mb-6">
                    <img
                        src="/static/curium-logo.svg"
                        alt="Company Logo"
                        className="mx-auto h-16 w-auto"
                    />
                </div>
                <h2 className="text-primary-600 mb-4 text-3xl font-bold">Welcome!</h2>
                <p className="mb-8 text-lg text-gray-700">
                    We're securely authenticating your account. This will only take a moment.
                </p>
                <div className="flex flex-col items-center justify-center">
                    <Spin size="large" />
                    <p className="text-md mt-6 text-gray-600">
                        Preparing your personalized experience...
                    </p>
                </div>
            </div>
        </ComponentLoading>
    );
}
