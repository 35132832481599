import React, { createContext } from 'react';
import { EventEmitter } from '@/utils/eventEmitter';

type PropsType = {
    children?: React.ReactNode;
};

type DefaultEvents = Record<string, any | undefined>;

export const EventEmitterContext = createContext<EventEmitter<DefaultEvents>>(null as any);

const EventEmitterProvider: React.FC<PropsType> = (props) => {
    return <EventEmitterContext.Provider value={new EventEmitter()}>{props.children}</EventEmitterContext.Provider>;
};

export default EventEmitterProvider;
