import { CuriumApp } from '@/App';
import { theme } from '@/styles/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { App as AntdApp, ConfigProvider } from 'antd';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { navRoutes } from '~/navigation/navRoutes';
import EventEmitterProvider from './components/Providers/EventEmitterProvider';
import { TRpc } from './components/TRpc';
import { ApolloSetup } from './graphql';
import { useAuthStore } from './stores/AuthStore';
export function AppSetup() {
    const { auth0UseRefreshToken, enableDebugInfo } = useFlags();
    const ldClient = useLDClient();
    const authStore = useAuthStore.getState();

    useEffect(() => {
        if (ldClient) {
            authStore.setLDClient(ldClient);
        }
    }, [ldClient]);

    useEffect(() => {
        if (enableDebugInfo) {
            console.log('🚀 ~ AppSetup ~ auth0UseRefreshToken:', auth0UseRefreshToken);
        }
    }, [auth0UseRefreshToken]);

    return (
        <>
            <ConfigProvider theme={theme}>
                <AntdApp component={false}>
                    <GoogleReCaptchaProvider
                        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY!}
                    >
                        <ApolloSetup>
                            <Auth0Provider
                                domain={import.meta.env.VITE_AUTH0_DOMAIN!}
                                clientId={import.meta.env.VITE_AUTH0_CLIENT_ID!}
                                authorizationParams={{
                                    audience: import.meta.env.VITE_AUTH0_AUDIENCE!,
                                    redirect_uri: `${window.location.origin}${navRoutes.public_authCallback.path}`,
                                }}
                                useRefreshTokens={auth0UseRefreshToken}
                            >
                                <TRpc>
                                    <BrowserRouter>
                                        <EventEmitterProvider>
                                            <CuriumApp />
                                        </EventEmitterProvider>
                                    </BrowserRouter>
                                </TRpc>
                            </Auth0Provider>
                        </ApolloSetup>
                    </GoogleReCaptchaProvider>
                </AntdApp>
            </ConfigProvider>
        </>
    );
}
