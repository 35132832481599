import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full">
            <div className="flex items-center justify-center h-screen">
                <Result
                    status="warning"
                    title="404 - Page not found"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Go back
                        </Button>
                    }
                />
            </div>
        </div>
    );
};
