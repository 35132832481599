import './config/sentry';
import Joi from 'joi';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import './styles/main.sass';
import './styles/main.css';
import { AppSetup } from './AppSetup';
import { uuid } from '~/global/utils/general';
import { LOCAL_STORAGE_KEY } from '~/global/constants/localStorage';

configure({
    enforceActions: 'never',
});

//@ts-expect-error BigInt.prototype.toJSON is not defined in TypeScript
BigInt.prototype.toJSON = function () {
    const int = Number.parseInt(this.toString());
    return int ?? this.toString();
};

const userKey = localStorage.getItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY) || uuid();
localStorage.setItem(LOCAL_STORAGE_KEY.LD_ANONYMOUS_USER_KEY, userKey);

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID!,
        context: {
            kind: 'user',
            anonymous: true,
            key: userKey,
        },
    });

    if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging') {
        LogRocket.init('cuen3v/curium');
    }

    const container = document.getElementById('root');
    const root = createRoot(container!);

    window.Joi = Joi;

    root.render(
        //<React.StrictMode>
        <LDProvider>
            <AppSetup />
        </LDProvider>,
        // </React.StrictMode>,
    );
})();
