import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { errorMessage } from '@/components/Common/errorMessage';
import { useAuthStore } from '@/stores/AuthStore';
import { useGetAccessToken } from '@/hooks/auth/useGetAccessToken';
import { logInDev } from '@/utils/general';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { App, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SYSTEM_ORG_ID } from '~/global/constants/org';
import { navRoutes } from '~/navigation/navRoutes';

export function AdminAuthCallback() {
    const { message } = App.useApp();
    const { logout, handleRedirectCallback } = useAuth0();
    const getAccessToken = useGetAccessToken();
    const navigate = useNavigate();
    const authStore = useAuthStore();
    const authInProgressRef = useRef(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        (async () => {
            queryClient.clear();

            logInDev('AdminAuthCallback useEffect');
            if (authInProgressRef.current && !authStore.isLoginInProgress) {
                return;
            }

            try {
                authInProgressRef.current = true;
                logInDev('logging in from /callback');

                const params = new URLSearchParams(window.location.search);
                const error = params.get('error');
                if (error) {
                    if (error === 'login_required') {
                        await errorMessage.showAsync(new Error('Login required'));
                    } else if (error === 'access_denied') {
                        await errorMessage.showAsync(
                            params.get('error_description'),
                            'Access Denied',
                        );
                    }
                    authStore.logout(logout);
                    return;
                }

                const appStateWrapper = (await handleRedirectCallback()) as {
                    appState?: { redirectTo?: string };
                };
                const redirectTo = appStateWrapper?.appState?.redirectTo;

                const accessToken = await getAccessToken(SYSTEM_ORG_ID.SYSTEM_CONSOLE, true);
                await authStore.login(accessToken);

                navigate(redirectTo ? redirectTo : navRoutes.adminPortal.home.path);
            } catch (e) {
                message.info('Logging out...');
                errorMessage.show(e);
                authStore.logout(logout);
            } finally {
                authInProgressRef.current = false;
                message.destroy();
            }
        })();
    }, []);

    return (
        <ComponentLoading isFullPage>
            <div className="hover:shadow-3xl mx-auto max-w-md transform rounded-xl bg-white p-10 text-center shadow-2xl transition-all duration-500 ease-in-out">
                <div className="mb-6">
                    <img
                        src="/static/curium-logo.svg"
                        alt="Company Logo"
                        className="mx-auto h-16 w-auto"
                    />
                </div>
                <h2 className="text-primary-600 mb-4 text-3xl font-bold">Welcome!</h2>
                <p className="mb-8 text-lg text-gray-700">
                    We're securely authenticating your account. This will only take a moment.
                </p>
                <div className="flex flex-col items-center justify-center">
                    <Spin size="large" />
                    <p className="text-md mt-6 text-gray-600">
                        Preparing your personalized experience...
                    </p>
                </div>
            </div>
        </ComponentLoading>
    );
}
