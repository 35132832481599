import { useEffect, useState } from 'react';

export function useIsPublicSection(): boolean {
    const [isPublicSection, setIsPublicSection] = useState(false);

    useEffect(() => {
        setIsPublicSection(location.href.includes('/public'));
    }, []);

    return isPublicSection;
}
