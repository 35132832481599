import { selectOrg, useAuthStore } from '@/stores/AuthStore';
import { matchPath } from 'react-router-dom';
import { SYSTEM_ORG_ID } from '~/global/constants/org';

/**
 * Returns the orgId from the URL or the auth store. If the orgId is not found, it returns -999.
 * Even if the orgId is returned, it does not guarantee that the user is authorized to access the org.
 *
 * @returns {number} - The orgId
 *
 **/
export function useOrgId() {
    const org = useAuthStore(selectOrg);
    if (org) {
        return org.id;
    }
    const consoleMatch = matchPath('/console/:orgId/*', location.pathname || '');
    if (consoleMatch?.params.orgId) {
        return Number(consoleMatch?.params.orgId);
    }
    if (location.pathname.toLowerCase().startsWith('/admin-console')) {
        return SYSTEM_ORG_ID.SYSTEM_CONSOLE;
    }
    const publicOrgMatch = matchPath('/public/:orgId/*', location.pathname || '');

    if (publicOrgMatch?.params.orgId) {
        return Number(publicOrgMatch?.params.orgId);
    }
    return SYSTEM_ORG_ID.NOT_APPLICABLE;
}
