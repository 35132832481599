/**
 * Synchronizes the local user permissions in JWT with the user permissions in the database and in Auth0
 */

import { gql } from '@/__generated__';
import { ProductFruitsSetup } from '@/components/ProductFruitsSetup';
import { useAuthStore } from '@/stores/AuthStore';
import { useGetAccessToken } from '@/hooks/auth/useGetAccessToken';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { logInDev } from '@/utils/general';
import { useSubscription } from '@apollo/client';
import { useState } from 'react';

type Props = {
    children: React.ReactNode;
};

export const UserPermissionSync = ({ children }: Props) => {
    const user = useCurrentUser();
    const authStore = useAuthStore();
    const [lastUpdated, setLastUpdated] = useState<string | null>(null);
    const getAccessToken = useGetAccessToken();

    useSubscription(UserUpdated, {
        variables: {
            id: user.id,
        },
        skip: !user.id,
        onData: async (opts) => {
            // Don't run the first time
            if (!lastUpdated) {
                setLastUpdated(opts.data.data?.UserByPk?.lastSuccessfulSync);
                return;
            }
            // If the last updated time is the same, don't run
            if (lastUpdated === opts.data.data?.UserByPk?.lastSuccessfulSync) {
                return;
            }

            setLastUpdated(opts.data.data?.UserByPk?.lastSuccessfulSync);

            logInDev('User permissions updated');

            const accessToken = await getAccessToken(user.orgId, false);
            await authStore.reloadUser(accessToken);
        },
    });

    return (
        <>
            <ProductFruitsSetup />
            {children}
        </>
    );
};

const UserUpdated = gql(/* GraphQL */ `
    subscription UserUpdated($id: bigint!) {
        UserByPk(id: $id) {
            lastSuccessfulSync
        }
    }
`);
