import { currencyFormat } from '@/global/constants';
import { Paths } from '@/types/common';
import { green, orange, red } from '@ant-design/colors';
import numeral from 'numeral';
import rfdc from 'rfdc';
import { v4 } from 'uuid';
import { LOCAL_STORAGE_KEY } from '~/global/constants/localStorage';

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @deprecated Use uuid from global directory
 */
export function uuid() {
    return v4();
}

export function logInDev(...data: unknown[]) {
    if (import.meta.env.DEV) {
        console.log(...data);
    } else {
        const enableDevLog = localStorage.getItem(LOCAL_STORAGE_KEY.ENABLE_DEV_INFO);
        if (enableDevLog) {
            console.log(...data);
        }
    }
}

export function randomIndicatorColor() {
    const colors = [red[4], green[4], orange[4]];
    return colors[Math.floor(Math.random() * 3)];
}

/**
 * @deprecated Use useSafePath hook instead
 */
export function safePropPath<T>(path: Paths<T, 3>): string[] {
    return (path as string).split('.');
}

export function getUserFullName(
    user?:
        | { userFirstName?: string | null; userLastName?: string | null; name?: string | null }
        | null
        | undefined,
) {
    if (user) {
        return `${user.userFirstName ?? ''} ${user.userLastName ?? ''}`.trim() || user.name || '';
    } else {
        return '';
    }
}

export const flattenJSON = function (
    data: unknown,
    delimiter: string = '.',
    keyTransformer: (input: string) => string = (input) => input,
) {
    const result: Record<string, unknown> = {};
    const recurse = (cur: any, prop: string) => {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            let l, i;
            for (i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '.' + i);
            if (l === 0) result[prop] = [];
        } else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + '.' + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    };
    recurse(data, '');
    const cleanResult: Record<string, unknown> = {};
    Object.keys(result).forEach((key) => {
        cleanResult[
            key
                .split('.')
                .map((e) => keyTransformer(e))
                .join(delimiter)
        ] = result[key];
    });
    return cleanResult;
};

export function toYesNo(value: boolean | null | undefined) {
    return value === true ? 'yes' : 'no';
}

export function formatCurrency(
    number: number | null | undefined,
    options?: { emptyValue?: string; withSymbol?: boolean },
) {
    const _options = { ...{ emptyValue: '-', withSymbol: true }, ...options };
    if (number === null || number === undefined) {
        return _options.emptyValue;
    }
    return numeral(number).format(
        _options.withSymbol ? currencyFormat.withSymbol : currencyFormat.withoutSymbol,
    );
}

export function emptyToUndefined(value: string | null | undefined) {
    const _value = (value || '').trim();
    if (_value === '') {
        return undefined;
    } else {
        return value;
    }
}

export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
        const key = grouper(item);
        if (!store.has(key)) {
            store.set(key, [item]);
        } else {
            store.get(key)?.push(item);
        }
        return store;
    }, new Map<K, V[]>());
}

export function isEmptyObject(value: unknown) {
    return (
        Object.prototype.toString.call(value) === '[object Object]' &&
        JSON.stringify(value) === '{}'
    );
}

export function cloneDeep<T = unknown>(obj: T): T {
    return rfdc()(obj);
}

export function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number = 500,
): (...funcArgs: Parameters<T>) => Promise<ReturnType<T>> {
    let timeout: NodeJS.Timeout | null = null;
    let resolveQueue: Array<(value: ReturnType<T> | PromiseLike<ReturnType<T>>) => void> = [];

    return (...args: Parameters<T>) => {
        return new Promise<ReturnType<T>>((resolve) => {
            if (timeout) {
                clearTimeout(timeout);
            }

            resolveQueue.push(resolve);

            timeout = setTimeout(async () => {
                const result = await func(...args);
                for (const resolve of resolveQueue) {
                    resolve(result);
                }
                resolveQueue = [];
            }, wait);
        });
    };
}

export const SortOrder = {
    asc: 'asc',
    desc: 'desc',
} as const;

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];

export const NullsOrder = {
    first: 'first',
    last: 'last',
} as const;

export type NullsOrder = (typeof NullsOrder)[keyof typeof NullsOrder];

export type SortOrderInput = {
    sort: SortOrder;
    nulls?: NullsOrder;
};
