import { IsNever } from 'type-fest';
import { Route } from './navRoutes.js';
import { mapObjectValues } from '../global/utils/object.js';

function invariant(value: boolean, message?: string) {
    if (value === false || value === null || typeof value === 'undefined') {
        throw new Error(message);
    }
}

export type PathParam<Path extends string> =
    // check if path is just a wildcard
    Path extends '*'
        ? '*'
        : // look for wildcard at the end of the path
          Path extends `${infer Rest}/*`
          ? '*' | _PathParam<Rest>
          : // look for params in the absence of wildcards
            _PathParam<Path>;

type _PathParam<Path extends string> =
    // split path into individual path segments
    Path extends `${infer L}/${infer R}`
        ? _PathParam<L> | _PathParam<R>
        : // find params after `:`
          Path extends `${string}:${infer Param}`
          ? Param
          : // otherwise, there aren't any params present
            never;

export type GeneratePathFunction = <Path extends string, Params extends Record<string, string>>(
    route: { path: Path; searchParams?: Params },
    params?: {
        [key in PathParam<Path>]-?: string;
    },
    searchParams?: Partial<Params>,
) => string;

export const generatePathWithOrgId =
    <Path extends string, Params extends Record<string, string>>(orgId: number) =>
    (
        route: { path: Path; searchParams?: Params },
        params?: {
            [key in PathParam<Path>]-?: string;
        },
        searchParams?: Partial<Params>,
    ) => {
        const _params = { ...params, orgId: orgId.toString() };
        const path = _params
            ? route.path
                  .replace(/:(\w+)/g, (_, key: PathParam<Path> & 'orgId') => {
                      invariant(_params[key] != null, `Missing ":${key}" param`);
                      return _params[key]!;
                  })
                  .replace(/(\/?)\*/, (_, prefix, __, str) => {
                      const star = '*' as PathParam<Path> & 'orgId';

                      if (_params[star] == null) {
                          // If no splat was provided, trim the trailing slash _unless_ it's
                          // the entire path
                          return str === '/*' ? '/' : '';
                      }

                      // Apply the splat
                      return `${prefix}${_params[star]}`;
                  })
            : route.path;

        return (
            path +
            (searchParams
                ? '?' + new URLSearchParams(searchParams as Record<string, string>).toString()
                : '')
        );
    };

export function generatePath<R extends Route>(
    route: R,
    params: IsNever<PathParam<R['path']>> extends true
        ? undefined
        : Record<PathParam<R['path']>, string | number | bigint>,
    searchParams?: Partial<Record<keyof R['searchParams'], string | number | bigint>>,
    anchor?: R['anchors'],
) {
    const path = params
        ? route.path
              .replace(/:(\w+)/g, (_, key: PathParam<R['path']>) => {
                  invariant(params[key] != null, `Missing ":${key}" param`);
                  return String(params[key]);
              })
              .replace(/(\/?)\*/, (_, prefix, __, str) => {
                  const star = '*' as PathParam<R['path']>;

                  if (params[star] == null) {
                      // If no splat was provided, trim the trailing slash _unless_ it's
                      // the entire path
                      return str === '/*' ? '/' : '';
                  }

                  // Apply the splat
                  return `${prefix}${params[star]}`;
              })
        : route.path;

    const modifiedSearchParams = searchParams
        ? `?${new URLSearchParams(
              mapObjectValues(searchParams, ([, value]) => String(value)),
          ).toString()}`
        : '';
    const modifiedAnchor = anchor ? `#${anchor}` : '';

    return `${path}${modifiedSearchParams}${modifiedAnchor}`;
}
