import { Spin } from 'antd';
import React from 'react';

type Props = {
    isFullPage?: boolean;
    removeText?: boolean;
    children?: React.ReactNode;
};

export const ComponentLoading = ({ isFullPage = false, removeText = false, children }: Props) => {
    const Spinner = () => (
        <div className="flex h-full items-center justify-center">
            <Spin
                spinning={true}
                tip={!removeText && 'Loading...'}
                size="large"
                className="flex justify-center [&>div]:w-auto [&>div]:pt-3"
            >
                <div />
            </Spin>
        </div>
    );

    return isFullPage ? (
        <div className="relative flex min-h-full w-screen flex-col justify-start bg-gray-100 md:justify-center">
            {children || <Spinner />}
        </div>
    ) : (
        <>{children || <Spinner />}</>
    );
};
