import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { createTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { AppRouter } from '../../server/src/routes/root';

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;

export type RouterInputs = inferRouterInputs<AppRouter>;

export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export type TRPCClient = ReturnType<typeof trpc.useUtils>;

export type StaleTime = number | 'immediate' | 'infinite' | 'default';

export function getStaleTime(staleTime?: StaleTime) {
    if (!staleTime || typeof staleTime === 'number') {
        return staleTime;
    }

    return {
        immediate: 0,
        infinite: Infinity,
        default: undefined,
    }[staleTime];
}
