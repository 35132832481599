import { gql } from '@/__generated__';
import { modal } from '@/components/Common/modal';
import { useAppStateStore } from '@/stores/AppStateStore';
import { useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOCAL_STORAGE_KEY } from '~/global/constants/localStorage';

export const AppBuildVersionSub = gql(/* GraphQL */ `
    subscription AppBuildVersionSub {
        AppSettingByPk(name: "app_build_version") {
            name
            value
        }
    }
`);

/**
 * This hook is used to get the app build version from the server and compare it with the local storage.
 * If the app build version is different, it will show a modal to ask the user to reload the page.
 **/
export function useAppBuildVersion() {
    const appState = useAppStateStore();
    const [modalShown, setModalShown] = useState(false);

    const handleVersionChange = (newVersion: string) => {
        const localVersion = localStorage.getItem(LOCAL_STORAGE_KEY.APP_BUILD_VERSION);
        localStorage.setItem(LOCAL_STORAGE_KEY.APP_BUILD_VERSION, newVersion);

        if (!localVersion) {
            return;
        }

        if (newVersion !== localVersion && !modalShown) {
            setModalShown(true);
            showUpdateModal();
        }
    };

    const showUpdateModal = () => {
        modal.confirm({
            title: 'A new version of the app is available.',
            content: 'Do you want to reload the page to get the latest version?',
            okText: 'Reload',
            cancelText: 'No',
            onOk: () => window.location.reload(),
            onCancel: () => setModalShown(false),
        });
    };

    useSubscription(AppBuildVersionSub, {
        fetchPolicy: 'network-only',
        onData: (options) => {
            const appBuildVersion = options.data.data?.AppSettingByPk?.value;
            if (appBuildVersion) {
                appState.setAppBuildVersion(appBuildVersion);
                handleVersionChange(appBuildVersion);
            }
        },
    });

    useEffect(() => {
        return () => setModalShown(false);
    }, []);
}
