import { useAuthStore } from '@/stores/AuthStore';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Logout() {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const authStore = useAuthStore();

    useEffect(() => {
        authStore.logout(logout);
        navigate('/');
    }, []);

    return <></>;
}
