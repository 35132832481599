import rfdc from 'rfdc';

export const mapObjectKeysToValues = <O extends Record<string, any>>(object: O) =>
    Object.fromEntries(Object.keys(object).map((key) => [key, key])) as { [K in keyof O]: K };

export const mapObjectValues = <O extends Record<string, any>, R>(
    object: O,
    callback: ([key, value]: [keyof O, O[keyof O]], object: O) => R,
) =>
    Object.fromEntries(
        Object.entries(object || {}).map(([key, value]) => [key, callback([key, value], object)]),
    ) as Record<keyof O, R>;

export const stabilizeNullFields = <O extends Record<string, any>>(object: O) =>
    Object.fromEntries(
        Object.entries(object).map(([key, value]) => [key, value === null ? undefined : value]),
    ) as {
        [K in keyof O]: null extends O[K] ? Exclude<O[K], null> | undefined : O[K];
    };

/**
 * Deep clone an object using structured clone algorithm and delete properties
 * @param obj Object to clone
 * @returns Cloned object
 **/
export function deleteObjectProperties<T extends Record<string, unknown>, K extends keyof T>(
    obj: T,
    ...keys: K[]
): Omit<T, K> {
    const newObj = rfdc()(obj);
    keys.forEach((key) => {
        delete newObj[key];
    });
    return newObj;
}
