import { useAppStateStore } from '@/stores/AppStateStore';
import { CloudUploadOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Modal, Progress, theme, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

export const UploadStatusModal = observer(() => {
    const appState = useAppStateStore();
    const { token } = theme.useToken();

    return (
        <Modal
            open={appState.pendingUploads.length > 0}
            title={
                <div className="flex items-center">
                    <CloudUploadOutlined className="mr-2 text-xl" />
                    <span>File upload in progress</span>
                </div>
            }
            footer={
                <Button
                    danger
                    icon={<StopOutlined />}
                    onClick={() => appState.cancelCurrentUpload()}
                >
                    Cancel Upload
                </Button>
            }
            centered
            closable={false}
            width={600}
        >
            <div className="pb-4">
                <Typography.Text>Uploading your files, please wait...</Typography.Text>
            </div>
            <div className="space-y-4">
                {appState.pendingUploads.map((upload) => (
                    <div key={upload.uid} className="rounded-md bg-gray-50 p-3">
                        <div className="mb-2 flex items-center justify-between">
                            <Typography.Text strong className="max-w-[400px] truncate">
                                {upload.name}
                            </Typography.Text>
                        </div>
                        <Progress
                            percent={upload.percent}
                            status="active"
                            strokeColor={token.colorPrimary}
                        />
                    </div>
                ))}
            </div>
        </Modal>
    );
});
