import { useOrgId } from '@/hooks/Org/useOrgId';
import { useEffect, useState } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { generatePath } from '~/navigation/navigation';
import { navRoutes } from '~/navigation/navRoutes';
import { useIsPublicSection } from '@/hooks/useIsPublicSection';
import { FallbackProps } from 'react-error-boundary';

export function ErrorFallback(props: FallbackProps) {
    const orgId = useOrgId();
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const isPublicSection = useIsPublicSection();

    useEffect(() => {
        if (import.meta.env.DEV) {
            return;
        }

        const _ = async () => {
            if (props.error && !isErrorModalVisible) {
                try {
                    setIsErrorModalVisible(true);
                    await errorMessage.showAsync(props.error);
                    if (isPublicSection) {
                        location.href = generatePath(navRoutes.public_root, undefined);
                    } else {
                        location.href = generatePath(navRoutes.home, { orgId });
                    }
                } finally {
                    setIsErrorModalVisible(false);
                }
            }
        };
        void _();
    }, [props.error]);

    return <></>;
}
