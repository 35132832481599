import { useGetAccessToken } from '@/hooks/auth/useGetAccessToken';
import { useCurrentOrg } from '@/hooks/Org/useCurrentOrg';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useIsOffline } from '@/hooks/useIsOffline';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { useAuthStore } from '@/stores/AuthStore';
import { logInDev } from '@/utils/general';
import { decodeJwtToken } from '@/utils/security';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JwtClaims } from '~/global/utils/jwt';
import { navRoutes } from '~/navigation/navRoutes';
import * as Sentry from '@sentry/react';

type Props = {
    children: React.ReactNode;

    loginUrl: string;
};

type ShowUnauthorizedModal = [false] | [true, reason: 'authTokenExpired' | 'unauthorized'];

export function AccessTokenRefresher({ children, loginUrl }: Props) {
    const [showUnauthorizedModal, setShowUnauthorizedModal] = useState<ShowUnauthorizedModal>([
        false,
    ]);
    const authStore = useAuthStore();
    const isOffline = useIsOffline();
    const orgId = useOrgId();
    const navigate = useNavigate();
    const getAccessToken = useGetAccessToken();

    const user = useCurrentUser();
    const org = useCurrentOrg();

    useEffect(() => {
        // Refresh JWT token
        const timerId = setInterval(
            async () => {
                try {
                    if (
                        isOffline ||
                        authStore.isLoginInProgress ||
                        authStore.isLogoutInProgress ||
                        !authStore.getIsAuthenticated() ||
                        showUnauthorizedModal
                    ) {
                        return;
                    }
                    Sentry.addBreadcrumb({
                        category: 'AccessTokenRefresher',
                        message: 'Refreshing JWT token',
                    });

                    const accessToken = await getAccessToken(orgId, false);
                    await authStore.reloadUser(accessToken);

                    const tokenDecoded = decodeJwtToken(accessToken || '');
                    if (
                        !tokenDecoded ||
                        tokenDecoded['https://curium.app/claims'].orgId !== orgId
                    ) {
                        logDevInfo(tokenDecoded, orgId);
                        setShowUnauthorizedModal([true, 'unauthorized']);
                        return;
                    }
                } catch (error) {
                    Sentry.captureException(error, {
                        tags: {
                            location: 'AccessTokenRefresher',
                        },
                    });
                }
            },
            12 * 60 * 60 * 1000,
        );
        return () => {
            clearTimeout(timerId);
        };
    }, [isOffline, showUnauthorizedModal]);

    useEffect(() => {
        if (!import.meta.env.DEV) {
            LogRocket.identify(user.id.toString(), {
                name: user?.fullName || '',
                email: user?.email || '',
                org: org?.name || '',
                orgId: org?.id || '',
            });
        }
    }, [org.id, user.id]);

    useEffect(() => {
        if (authStore.authTokenExpired) {
            setShowUnauthorizedModal([true, 'authTokenExpired']);
        }
    }, [authStore.authTokenExpired]);

    return (
        <>
            <Modal
                title={
                    <div className="flex items-center">
                        <ExclamationCircleOutlined className="mr-2 text-red-500" />
                        Unauthorized
                    </div>
                }
                closable={false}
                open={showUnauthorizedModal[0]}
                okText="Login again"
                cancelText="Navigate to home page"
                cancelButtonProps={{ hidden: true }}
                onOk={() => {
                    navigate(
                        `${loginUrl}?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`,
                    );
                }}
                onCancel={() => {
                    navigate(navRoutes.public_root.path);
                }}
            >
                {showUnauthorizedModal[1] === 'authTokenExpired'
                    ? 'Your session has expired. Please login again.'
                    : 'You are not authorized to access this page.'}
            </Modal>
            {!showUnauthorizedModal[0] && <>{children}</>}
        </>
    );
}

function logDevInfo(tokenDecoded: JwtClaims | undefined, orgId: number) {
    if (tokenDecoded) {
        logInDev(
            'Unauthorized (1): invalid token',
            {
                label: 'Decoded Roles',
                value: tokenDecoded['https://curium.app/claims'].roles,
            },
            { label: 'Organization ID', value: orgId },
            {
                label: 'Decoded Organization ID',
                value: tokenDecoded['https://curium.app/claims'].orgId,
            },
        );
    } else {
        logInDev('Unauthorized (2): no token');
    }
}
