import { InMemoryCache } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

export const cache: InMemoryCache = new InMemoryCache({
    addTypename: false,
    typePolicies: {
        FormQuestion: {
            fields: {
                instanceId: {
                    read(value = null) {
                        return value ?? uuidv4();
                    },
                },
            },
        },
        FormOption: {
            fields: {
                instanceId: {
                    read(value = null) {
                        return value ?? uuidv4();
                    },
                },
            },
        },
    },
});
