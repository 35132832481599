import { selectUser, useAuthStore } from '@/stores/AuthStore';
import { BugOutlined } from '@ant-design/icons';
import { Collapse, FloatButton, Popover, Table } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import ReactJson from 'react-json-view';

export function DebugInfo() {
    const flags = useFlags();
    const [open, setOpen] = useState(false);
    const user = useAuthStore(selectUser);

    if (!flags.enableDebugInfo || !user) {
        return null;
    }

    const flagsData = Object.entries(flags).map(([key, value]) => ({
        key,
        flag: key,
        value: JSON.stringify(value),
    }));

    const { data: claims, ...userWithoutClaims } = user;

    return (
        <Popover
            title="Debug Info"
            open={open}
            content={
                <Collapse
                    size="small"
                    className="w-[500px]"
                    items={[
                        {
                            key: '1',
                            label: 'Feature Flags',
                            children: (
                                <Table
                                    dataSource={flagsData}
                                    columns={[
                                        {
                                            title: 'Flag',
                                            dataIndex: 'flag',
                                            key: 'flag',
                                        },
                                        {
                                            title: 'Value',
                                            dataIndex: 'value',
                                            key: 'value',
                                            render: (text) => (
                                                <code className="break-all text-xs">{text}</code>
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                    size="small"
                                />
                            ),
                        },
                        {
                            key: '2',
                            label: 'User',
                            children: (
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <ReactJson
                                        src={userWithoutClaims}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        enableClipboard={false}
                                    />
                                </div>
                            ),
                        },
                        {
                            key: '3',
                            label: 'Claims',
                            children: (
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <ReactJson
                                        src={claims}
                                        displayDataTypes={false}
                                        enableClipboard={false}
                                        displayObjectSize={false}
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
            }
            placement="leftTop"
            trigger="click"
        >
            <FloatButton icon={<BugOutlined />} onClick={() => setOpen(!open)} />
        </Popover>
    );
}
