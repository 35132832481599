import { UploadStatusModal } from '@/components/Common/Documents/UploadStatusModal';
import { useOrgId } from '@/hooks/Org/useOrgId';
import { useAuthStore } from '@/stores/AuthStore';
import { logInDev } from '@/utils/general';
import { decodeJwtToken } from '@/utils/security';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JwtClaims } from '~/global/utils/jwt';
import { AccessTokenRefresher } from '../AccessTokenRefresher';
import { UserPermissionSync } from '../UserPermissionSync';

interface Props {
    loginUrl: string;
    children: React.ReactNode;
}

export function RequireAuth({ children, loginUrl }: Props) {
    const navigate = useNavigate();
    const authStore = useAuthStore();
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const orgId = useOrgId();
    const restoringAccessTokenRef = useRef(false);
    const [restoringAccessToken, setRestoringAccessToken] = useState(false);

    useEffect(() => {
        // Load the access if user refreshes the page or navigates to a different page
        const _ = async () => {
            if (
                restoringAccessTokenRef.current ||
                authStore.isLoginInProgress ||
                authStore.isLogoutInProgress
            ) {
                return;
            }

            try {
                // Prevent re-entrancy before the previous request is completed
                restoringAccessTokenRef.current = true;
                // Set the state to true to show the loading spinner

                setRestoringAccessToken(true);

                // Check if the token is already in the store from login
                const accessToken = authStore.getAuthToken();
                const tokenDecoded = decodeJwtToken(accessToken);
                if (tokenDecoded && tokenDecoded['https://curium.app/claims'].orgId === orgId) {
                    setIsAuthenticating(false);
                    return;
                } else {
                    logDevInfo(tokenDecoded, orgId);
                    navigate(
                        `${loginUrl}?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`,
                    );
                    return;
                }
            } finally {
                restoringAccessTokenRef.current = false;
                setRestoringAccessToken(false);
            }
        };
        void _();
    }, []);

    const loading =
        isAuthenticating ||
        authStore.isOrgChangeInProgress ||
        authStore.isLogoutInProgress ||
        restoringAccessToken;

    return (
        <>
            {loading ? (
                <Spin spinning size="large" className="absolute inset-1/2" />
            ) : (
                <AccessTokenRefresher loginUrl={loginUrl}>
                    <UserPermissionSync>{children}</UserPermissionSync>
                    <UploadStatusModal />
                </AccessTokenRefresher>
            )}
        </>
    );
}

function logDevInfo(tokenDecoded: JwtClaims | undefined, orgId: number) {
    if (tokenDecoded) {
        logInDev(
            'Unauthorized (1): invalid token',
            {
                label: 'Decoded Roles',
                value: tokenDecoded['https://curium.app/claims'].roles,
            },
            { label: 'Organization ID', value: orgId },
            {
                label: 'Decoded Organization ID',
                value: tokenDecoded['https://curium.app/claims'].orgId,
            },
        );
    } else {
        logInDev('Unauthorized (2): no token');
    }
}
