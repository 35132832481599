import { useAuth0 } from '@auth0/auth0-react';

export function useGetAccessToken() {
    const { getAccessTokenSilently } = useAuth0();

    const getAccessToken = async (requestedOrgId: number | undefined, isLogin: boolean) => {
        return await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: 'openid profile email offline_access',
                orgId: requestedOrgId,
                isLogin: isLogin,
                ignoreCache: true,
                ...(requestedOrgId === -1 && {
                    isAdminLogin: true,
                }),
            },
            cacheMode: 'off',
        });
    };

    return getAccessToken;
}
