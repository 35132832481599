import { create } from 'zustand';
import type { AppState } from './types';

export const useAppStateStore = create<AppState>((set, get) => ({
    isOnline: true,
    appBuildVersion: '',
    cancelUploadSignal: null,
    uploadCanceler: null,
    pendingUploads: [],
    claimLodgementSettings: {
        fieldRequiredMessage: 'Please complete this section before continuing the claim.',
    },
    drawer: {
        show: false,
    },

    setIsOnline: (isOnline) => set({ isOnline }),
    setAppBuildVersion: (version) => set({ appBuildVersion: version }),
    addPendingUpload: (upload) =>
        set((state) => ({
            pendingUploads: [
                ...state.pendingUploads,
                { ...upload, status: 'uploading', percent: 0 },
            ],
        })),

    removePendingUpload: (uid) =>
        set((state) => ({
            pendingUploads: state.pendingUploads.filter((upload) => upload.uid !== uid),
        })),

    cancelCurrentUpload: () => {
        const { uploadCanceler } = get();
        uploadCanceler?.();
        set({ pendingUploads: [], uploadCanceler: null });
    },

    setUploadCanceler: (canceler) => set({ uploadCanceler: canceler }),

    updatePendingUploadProgress: (uid, percent) =>
        set((state) => ({
            pendingUploads: state.pendingUploads.map((upload) =>
                upload.uid === uid ? { ...upload, percent } : upload,
            ),
        })),
}));
