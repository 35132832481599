import { RequireAuth } from '@/components/Auth/RequireAuth';
import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { ErrorFallback } from '@/components/Common/ErrorFallback';
import { errorMessage } from '@/components/Common/errorMessage';
import { modal } from '@/components/Common/modal';
import { OfflineOverlay } from '@/components/Common/OfflineOverlay';
import { DebugInfo } from '@/components/DebugInfo';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { useAppBuildVersion } from '@/hooks/useAppBuildVersion';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useOfflineDetection } from '@/hooks/useOfflineDetection';
import { AdminAuthCallback } from '@/pages/Authentication/AdminAuthCallback';
import { AuthCallback } from '@/pages/Authentication/AuthCallback';
import { LandingPage } from '@/pages/LandingPage';
import { Logout } from '@/pages/Logout';
import { NotFound } from '@/pages/NotFound';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { Modal, Typography, notification } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { navRoutes } from '~/navigation/navRoutes';
import { useNavigate } from './hooks/useNavigate';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Console = loadable(() => import('@/pages/Console'), {
    resolveComponent: (comps) => comps.Console,
    fallback: <ComponentLoading />,
});
const AdminConsole = loadable(() => import('@/pages/AdminConsole'), {
    resolveComponent: (comps) => comps.AdminConsole,
    fallback: <ComponentLoading />,
});
const Public = loadable(() => import('@/pages/Public'), {
    resolveComponent: (comps) => comps.Public,
    fallback: <ComponentLoading />,
});
const Login = loadable(() => import('@/pages/Authentication/Login'), {
    resolveComponent: (comps) => comps.Login,
    fallback: <ComponentLoading isFullPage />,
});
const AdminLogin = loadable(() => import('@/pages/Authentication/AdminLogin'), {
    resolveComponent: (comps) => comps.AdminLogin,
    fallback: <ComponentLoading isFullPage />,
});
const AuthenticatedLayout = loadable(() => import('@/components/Layout/AuthenticatedLayout'), {
    resolveComponent: (comps) => comps.AuthenticatedLayout,
});

export function CuriumApp() {
    const [modalApi, contextHolder] = Modal.useModal();

    useOfflineDetection();
    useDocumentTitle();
    useAppBuildVersion();

    // We don't want to wait for useEffect as it will block the rendering
    errorMessage.init(modalApi);
    modal.init(modalApi);

    return (
        <>
            {contextHolder}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <OfflineOverlay />
                <MaintenanceModeOverlay>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="login" element={<Login />} />
                        <Route path="admin-login" element={<AdminLogin />} />
                        <Route path="callback" element={<AuthCallback />} />
                        <Route path="admin-callback" element={<AdminAuthCallback />} />
                        <Route path="logout" element={<Logout />} />
                        <Route
                            path="public/*"
                            element={
                                <PublicLayout>
                                    <Public />
                                </PublicLayout>
                            }
                        />
                        <Route
                            path="console/:orgId/*"
                            element={
                                <RequireAuth loginUrl="/login">
                                    <AuthenticatedLayout>
                                        <Console />
                                    </AuthenticatedLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="admin-console/*"
                            element={
                                <RequireAuth loginUrl="/admin-login">
                                    <AuthenticatedLayout>
                                        <AdminConsole />
                                    </AuthenticatedLayout>
                                </RequireAuth>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <DebugInfo />
                </MaintenanceModeOverlay>
            </ErrorBoundary>
        </>
    );
}

function MaintenanceModeOverlay({ children }: { children: ReactNode }) {
    const { inMaintenanceMode } = useFlags();
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);

    useEffect(() => {
        Sentry.setTag('in-maintenance-mode', inMaintenanceMode ? true : false);
        if (inMaintenanceMode) {
            setLocation(window.location.href);
            navigate({ route: navRoutes.public_root });
        } else {
            if (location) {
                notification.success({
                    message: 'Maintenance Complete',
                    placement: 'top',
                    description:
                        'The system maintenance is complete. Redirecting you to your previous location...',
                    duration: 3,
                    onClose: () => {
                        window.location.href = location;
                        setLocation(null);
                    },
                });
            }
        }
    }, [inMaintenanceMode]);

    if (inMaintenanceMode) {
        return (
            <>
                <div className="flex h-screen w-full items-center justify-center bg-amber-300">
                    <div className="text-lg">
                        <Typography.Title level={2}>Under Maintenance</Typography.Title>
                        <Typography.Text>
                            The site is undergoing upgrade. We'll be back soon.
                        </Typography.Text>
                    </div>
                </div>
            </>
        );
    } else {
        return <>{children}</>;
    }
}
